<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">关爱员工—公司解决员工子女入学和接送问题</div>
          <div class="notice-time">
            <div>发布时间： 2021/09/04 10:37:29</div>
            <div>浏览： 132次</div>
          </div>
          <p>
            孩子是祖国的未来，也是民族的希望，让他们健康快乐地成长、接受良好的教育是每个家长的期望。公司的员工来自五湖四海，一年难得和自己的子女见一次，所以能把孩子接到身边生活是不少员工的心声。可是，外来务工人员子女的教育问题却很难解决。
          </p>
          <p style="margin-top: 15px;">
            杭州里德秉承“幸福员工”理念，在了解到公司员工的需求后，公司积极与各个学校联系，通过多方协调统筹，帮助13个员工子女成功入学。
          </p>
          <div class="notice-img-1">
            <img src="./1.png" srcset="./1@1x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <img src="./2.png" srcset="./2@2x.png 2x" alt="">
          </div>
          <p>
            员工子女的入学难问题解决了，但接送问题怎么办呢？针对这一情况，公司立即统计了需要接送孩子的名单，制定了几条不同的接送路线，安排好接送人员。
          </p>
          <div class="notice-img-2">
            <img src="./3.png" srcset="./3@3x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <img src="./4.png" srcset="./4@4x.png 2x" alt="">
          </div>
          <p style="margin-top: 15px;">
            “教育对孩子的成长影响很大，我们做家长的哪个不希望子女留在自己身边。非常感谢公司帮我们解决了孩子上学和接送问题，让我们能免去后顾之忧，安心工作。”一名员工这样说道。
          </p>

          <p style="margin-top: 15px;">
            孩子的成长需要父母的鼓励和陪伴，再多的物质条件也无法填充那颗渴望爱的心。“留守儿童”一直是个备受关注的群体，父母为了维持生计远赴他乡打工，把孩子留给家里的老人，这对孩子的成长是非常不利的。经过调查了解，公司里有不少员工子女也正面临这种情况。

          </p>
          <p style="margin-top: 15px;">
            公司的成长与发展离不开每一个员工的努力与汗水，一直以来，公司倾情倾力为员工解难事，办实事，始终坚持“以人为本、人文关怀”的工作理念，于2019年创办托班解决员工子女托管问题。杭州里德欢迎更多的“留守儿童”能来到父母身边生活，公司也会倾尽全力帮助解决实际问题。我们一起让“留守儿童”不再留守！
          </p>
          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice5">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice1">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
